import React from 'react';
import Leiding from '../components/Leiding';
import TakInfo from '../components/TakInfo';

export default function Tak() {
  return (
    <div className="textelement">
      <TakInfo />
      <Leiding />
    </div>
  );
}
