import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const KNOOP = gql`
  query GetKnoop($id: ID!) {
    tak(id: $id) {
      data {
        id
        attributes {
          naam
          leidings {
            data {
              id
              attributes {
                naam
                totem
              }
            }
          }
        }
      }
    }
  }
`;

export default function DeLeiding() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(KNOOP, {
    variables: { id: id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  // Log the data to see its structure
  console.log(data);

  // Ensure leiding is defined and is an array
  const leidings = data.tak?.data?.attributes?.leidings?.data;

  if (!Array.isArray(leidings)) {
    return <p>No Leiding available</p>;
  }

  return (
    <div>
      {leidings.map((leiding) => (
        <div key={leiding.id} className="leiding-card">
          <h2>{leiding.attributes.naam}</h2>
          <p>{leiding.attributes.totem}</p>
        </div>
      ))}
    </div>
  );
}
