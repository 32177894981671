import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Ensure this path is correct
import './assets/css/styles.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import { Navigation, Pagination, Grid } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { format, parseISO, startOfDay } from 'date-fns';

const EVENTS = gql`
  query GetEvenementen {
    events {
      data {
        id
        attributes {
          title
          datum
          description
          thumbnail {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Carousel = () => {
  const { loading, error, data } = useQuery(EVENTS);

  // Handle loading and error states
  if (loading) return <p>Loading takken...</p>;
  if (error) return <p>Error bij ophalen takken</p>;

  // Get today's date at the start of the day
  const today = startOfDay(new Date());

  // Extract events from the response data
  const events = data.events.data
    .filter((event) => {
      // Parse the event date
      const eventDate = parseISO(event.attributes.datum);
      // Return true if the event date is today or in the future
      return eventDate >= today;
    })
    .sort(
      (a, b) => new Date(a.attributes.datum) - new Date(b.attributes.datum)
    ); // Sort events by date

  // Function to truncate text to a maximum length
  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  return (
    <div className="card__container">
      <Swiper
        loop={true}
        spaceBetween={60}
        grabCursor={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          900: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3,
          },
        }}
        modules={[Navigation, Pagination]}
      >
        {events.map((event) => (
          <SwiperSlide className="cardsdiv" key={event.id}>
            {event.attributes.thumbnail && event.attributes.thumbnail.data && (
              <img
                className="card__img"
                src={`https://api.scouts121.be${event.attributes.thumbnail.data.attributes.url}`} // Adjusted path
                alt={event.attributes.title}
              />
            )}
            <Link className="centered" to={`/evenementen/${event.id}`}>
              <h3 className="link">{event.attributes.title}</h3>
            </Link>
            <div className="eventGrid">
              <div>
                <div className="centered">
                  <p>{format(parseISO(event.attributes.datum), 'MMM')}</p>
                  <h3>{format(parseISO(event.attributes.datum), 'd')}</h3>
                </div>
              </div>
              <div className="grid-col-2">
                <div className="centered">
                  <p className="link">
                    {truncateText(event.attributes.description, 100)}
                  </p>
                  <p>
                    <Link to={`/evenementen/${event.id}`}>
                      <span className="meer">Lees Meer</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-next">
          <i className="ri-arrow-right-s-line"></i>
        </div>
        <div className="swiper-button-prev">
          <i className="ri-arrow-left-s-line"></i>
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>
    </div>
  );
};

export default Carousel;
