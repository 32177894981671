import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { format, isAfter, isBefore, subDays, addDays } from 'date-fns';
import { Helmet } from 'react-helmet';
const KNOOP = gql`
  query GetKnoop($id: ID!) {
    tak(id: $id) {
      data {
        id
        attributes {
          naam
          knoops {
            data {
              id
              attributes {
                knoopEntry
                eindUur
                startUur
                datum
              }
            }
          }
        }
      }
    }
  }
`;

export default function Deknoop() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(KNOOP, {
    variables: { id: id },
  });

  if (loading) return <p className="textelement knoop">Loading...</p>;
  if (error) return <p className="textelement knoop">Error</p>;

  // Log the data to see its structure
  console.log(data);

  // Ensure knoops is defined and is an array
  const knoops = data.tak?.data?.attributes?.knoops?.data;

  if (!Array.isArray(knoops)) {
    return (
      <div className="textelement knoop">
        <Helmet>
          <title>
            {' '}
            {data.tak.data.attributes.naam} Knoop | Scouts 121 Oude-God Mortsel
          </title>
          <meta
            name="description"
            content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
          />
          <meta
            name="keywords"
            content="contact, contacteren, e-mail, scouts121, 121, scouts"
          />
          <meta name="HandheldFriendly" content="true" />
        </Helmet>
        <h1>{data.tak.data.attributes.naam}</h1>

        <p>Oeps, er zijn toekomstige vergaderingen te vinden</p>
      </div>
    );
  }

  const today = new Date();
  const last7Days = subDays(today, 7);
  const next30Days = addDays(today, 30);

  const filteredKnoops = knoops.filter((knoop) => {
    const knoopDate = new Date(knoop.attributes.datum);
    return isAfter(knoopDate, last7Days) && isBefore(knoopDate, next30Days);
  });

  if (filteredKnoops.length === 0) {
    return (
      <div className="textelement knoop">
        <Helmet>
          <title>
            {' '}
            {data.tak.data.attributes.naam} Knoop | Scouts 121 Oude-God Mortsel
          </title>
          <meta
            name="description"
            content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
          />
          <meta
            name="keywords"
            content="contact, contacteren, e-mail, scouts121, 121, scouts"
          />
          <meta name="HandheldFriendly" content="true" />
        </Helmet>
        <h1>{data.tak.data.attributes.naam}</h1>

        <p>Oeps, er zijn toekomstige vergaderingen te vinden</p>
      </div>
    );
  }

  return (
    <div className="textelement knoop">
      <Helmet>
        <title>
          {' '}
          {data.tak.data.attributes.naam} Knoop | Scouts 121 Oude-God Mortsel
        </title>
        <meta
          name="description"
          content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
        />
        <meta
          name="keywords"
          content="contact, contacteren, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <h1>{data.tak.data.attributes.naam}</h1>
      {filteredKnoops.map((knoop) => (
        <div key={knoop.id} className="knoop-card">
          <h2>{knoop.attributes.datum}</h2>
          <p>{knoop.attributes.knoopEntry}</p>
        </div>
      ))}
    </div>
  );
}
